import { Link, useNavigate } from "@remix-run/react";
import type { ReactNode } from "react";
import { tv } from "tailwind-variants";

export type CTAProps = {
  children: ReactNode;
  fillStyle?: "filled" | "outline";
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  target?: string;
  to?: string;
  variant?: "coral" | "coral-shadow" | "sky" | "sky-shadow";
  type?: "button" | "submit";
  disabled?: boolean;
  id?: string;
};

export function CTARedirect(
  props: CTAProps & {
    userLoggedIn: boolean;
    loginOrSignUp: "login" | "sign-up";
  }
) {
  return (
    <CTA
      {...{
        ...props,
        to: props.userLoggedIn
          ? props.to
          : `/${props.loginOrSignUp}?redirectTo=${encodeURIComponent(
              props.to ?? ""
            )}`,
      }}
    />
  );
}

const tvCTA = tv({
  base: "rounded-3xl px-3 py-1 whitespace-nowrap md:px-4 md:py-1.5 outline-none",
  variants: {
    fillStyle: {
      filled: "bg-sky-600 text-white",
      outline: "bg-white border-2 sky-600",
    },
    variant: {
      "coral-shadow": "",
      coral: "",
      sky: "",
      "sky-shadow": "",
    },
  },
  compoundVariants: [
    {
      fillStyle: "filled",
      variant: "coral",
      class: "bg-coral",
    },
    {
      fillStyle: "filled",
      variant: "coral-shadow",
      class:
        "bg-coral hover:shadow-lg hover:shadow-coral/50 ease-in duration-300",
    },
    {
      fillStyle: "filled",
      variant: "sky-shadow",
      class:
        "bg-sky-600 hover:shadow-lg hover:shadow-sky-600/50 ease-in duration-300",
    },
    {
      fillStyle: "filled",
      variant: "sky",
      class: "bg-sky-600",
    },
    {
      fillStyle: "outline",
      variant: "coral",
      class: "text-coral border-coral hover:text-coral/80 hover:bg-coral-50/50 ease-in duration-300",
    },
    {
      fillStyle: "outline",
      variant: "sky",
      class: "text-sky-600 border-sky-600 hover:text-sky-600/80 hover:bg-sky-50/50 ease-in duration-300",
    },
  ],
});

export function CTA({
  fillStyle = "filled",
  variant = "coral",
  children,
  className,
  onClick,
  target,
  to,
  type = "button",
  disabled = false,
  id,
}: CTAProps) {
  const navigate = useNavigate();
  onClick =
    onClick ??
    (to
      ? () => {
          if (to.startsWith("http")) {
            window.location.href = to;
          } else {
            navigate(to);
          }
        }
      : () => {});
  return target && to ? (
    <Link
      to={to}
      id={id}
      target={target}
      className={tvCTA({ fillStyle, variant, className })}
    >
      {children}
    </Link>
  ) : (
    <button
      id={id}
      onClick={onClick}
      className={tvCTA({ fillStyle, variant, className })}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
